<template>
    <div class="height__full">
        <div class="app-modal_In">
            <div class="app-modal__header d-flex f-between p-5 m-title-modal">
                <p class="large--title m-0">{{ $t('message.products') }}</p>
                <div>
                    <crm-store-update-close
                    :permission="$options.name"
                    :button_type="'close'"
                    @c-close="close()"
                    ></crm-store-update-close>
                </div>
            </div>
        </div>
    <div class="gc-card__body px-3 py-4">
        <div class="app-form__group mb-0">
            <div
                class="
                    gc-card
                    rounded-sm
                    mb-4
                    p-relative
                    mr-3
                    px-3 py-3
                "
            >
                <div class="mb-4">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <el-input ref="scanRef" @input="scanContainerProductBarcodeDebounce" :placeholder="$t('message.scan')" v-model="barcode_search"></el-input>
                        </el-col>
                        <el-col :span="6">
                            <div class="grid-content bg-purple p-2 font-weight-bold text-center">
                                {{ $t('message.quantity_y') }}: {{ product_total_count }}
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <el-button class="w-100" :loading="loadingButton" @click="setContainerPorductsInFilial(true)" size="medium" type="primary" plain>{{ $t('message.inventarization_all') }}</el-button>
                        </el-col>
                        <el-col :span="6">
                            <el-button class="w-100" :loading="loadingButton" @click="setContainerPorductsInFilial()" size="medium" type="primary" plain>{{ $t('message.inventarization') }}</el-button>
                        </el-col>
                    </el-row>
                </div>

                <div v-loading="loadingData">
                    <template>
                        <el-table 
                            header-row-class-name="header__class"
                            :row-class-name="tableRowClassName"
                            :data="dealProductsList" 
                            show-summary 
                            :summary-method="getSummaries" 
                            border stripe 
                            style="width: 100%">
                            <el-table-column type="index" width="50"> </el-table-column>
                            <el-table-column :label="$t('message.deal')" width="90">
                                <template slot-scope="scope"> {{ scope.row.deal_id }} </template>
                            </el-table-column>
                            <el-table-column :label="$t('message.name')">
                                <template slot-scope="scope"> 
                                    <span v-if="scope.row.type === 'product'">{{ scope.row.product ? scope.row.product.name : '' }} </span>
                                    <span v-if="scope.row.type === 'package'">{{ $t('message.package') }} </span>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('message.barcode')">
                                <template slot-scope="scope"> 
                                    <span>{{ scope.row.barcode }} </span>    
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('message.quantity_y')">
                                <template slot-scope="scope"> 
                                    <span v-if="scope.row.type === 'product'">{{ scope.row.remainder }}  </span>
                                    <span v-if="scope.row.type === 'package'"> 1 </span>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('message.current_quantity')">
                                <template slot-scope="scope">
                                    <div>
                                        <el-input
                                            :min="0"
                                            type="number"
                                            size="mini"
                                            @input="checkValue(scope.row)"
                                            v-model="scope.row.incoming_quantity"
                                            :disabled="(scope.row.batches && scope.row.batches.length > 1)"
                                        ></el-input>
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column :label="$t('message.to_filial')">
                                <template slot-scope="scope"> {{ scope.row.to_filial }} </template>
                            </el-table-column>
                            
                            <el-table-column :label="columns.measuring_type.title" v-if="columns.measuring_type.show">
                                <template slot-scope="scope">
                                    <select-measuring-type
                                        v-show="scope.row.type === 'product' && (!scope.row.batches || scope.row.batches.length <= 1)"
                                        :size="'mini'"
                                        :placeholder="columns.measuring_type.title"
                                        :id="scope.row.measuring_type_id"
                                        v-model="scope.row.measuring_type_id"
                                        @input="updateWeight(scope.row)"
                                    ></select-measuring-type>
                                </template>
                            </el-table-column>
                            <el-table-column :label="columns.weight.title">
                                <template slot-scope="scope">
                                    <!-- weight block (kg) -->
                                    <crm-product-slot-input
                                        v-model="scope.row.weight"
                                        :inputValue="scope.row.weight"
                                        :type="'number'"
                                        :size="'mini'"
                                        :disabled="scope.row.measuring_type_id === 2"
                                        :measurement_type="'kg'"
                                        :v_show="scope.row.type === 'product' && (!scope.row.batches || scope.row.batches.length <=1 )"
                                        @input="updatePoundAndKG(scope.row, 'weight_in_kg')"
                                    ></crm-product-slot-input>

                                    <crm-product-slot-input
                                        :inputValue="scope.row.total_weight"
                                        :type="'number'"
                                        :size="'mini'"
                                        :disabled="true"
                                        :measurement_type="'kg'"
                                        :v_show="scope.row.type === 'package'"
                                    ></crm-product-slot-input>

                                    <crm-product-slot-input
                                        :inputValue="showWeight(scope.row)"
                                        :type="'number'"
                                        :size="'mini'"
                                        :disabled="true"
                                        :measurement_type="'kg'"
                                        :v_show="scope.row.type === 'product' && scope.row.batches && scope.row.batches.length > 1"
                                    ></crm-product-slot-input>

                                    <!-- weight in pounds block (lbs) -->
                                     <crm-product-slot-input
                                        :v_show="scope.row.type === 'product' && (!scope.row.batches || scope.row.batches.length <=1 && !showInputCheck(scope.row))"
                                        v-model="scope.row.weight_in_pounds"
                                        :inputValue="scope.row.weight_in_pounds"
                                        :type="'number'"
                                        :size="'mini'"
                                        :class="'mt-1'"
                                        :measurement_type="'lbs'"
                                        @input="updatePoundAndKG(scope.row, 'weight_in_pounds')"
                                    ></crm-product-slot-input>

                                    <crm-product-slot-input
                                        :inputValue="parseFloat(scope.row.total_weight * $kg_to_pound).toFixed(6)"
                                        :type="'number'"
                                        :size="'mini'"
                                        :class="'mt-1'"
                                        :disabled="true"
                                        :measurement_type="'lbs'"
                                        :v_show="scope.row.type === 'package'"
                                    ></crm-product-slot-input>

                                     <crm-product-slot-input
                                        :inputValue="parseFloat((showWeight(scope.row) * $kg_to_pound))"
                                        :type="'number'"
                                        :size="'mini'"
                                        :class="'mt-1'"
                                        :disabled="true"
                                        :measurement_type="'lbs'"
                                        :v_show="scope.row.type === 'product' && scope.row.batches && scope.row.batches.length > 1"
                                    ></crm-product-slot-input>
                                   
                                </template>
                            </el-table-column>

                            <!-- <el-table-column :label="columns.weight_in_pounds.title">
                                <template slot-scope="scope">
                                    <el-input 
                                        v-show="scope.row.type === 'product' && (!scope.row.batches || scope.row.batches.length <=1 && !showInputCheck(scope.row))"
                                        :min="0" 
                                        @input="updatePoundAndKG(scope.row, 'weight_in_pounds')"
                                        type="number" 
                                        size="mini" 
                                        v-model="scope.row.weight_in_pounds"
                                    ></el-input>
                                    <span v-show="scope.row.type === 'package'"> {{ parseFloat(scope.row.total_weight * $kg_to_pound).toFixed(6) }} </span>
                                    <span v-show="scope.row.type === 'product' && scope.row.batches && scope.row.batches.length > 1"> {{ parseFloat((showWeight(scope.row) * $kg_to_pound)).toFixed(6) }} </span>
                                </template>
                            </el-table-column> -->

                            <el-table-column :label="columns.width.title" v-if="columns.width.show" width="90">
                                <template slot-scope="scope">
                                    <crm-product-slot-input
                                        v-model="scope.row.width"
                                        :inputValue="scope.row.width"
                                        :type="'number'"
                                        :size="'mini'"
                                        :measurement_type="'sm'"
                                        :v_show="showInputCheck(scope.row)"
                                        @input="updateWeight(scope.row)"
                                    ></crm-product-slot-input>
                                    <!-- <el-input
                                        v-show="showInputCheck(scope.row)"
                                        :min="0"
                                        type="number"
                                        size="mini"
                                        @input="updateWeight(scope.row)"
                                        v-model="scope.row.width"
                                    ></el-input> -->
                                </template>
                            </el-table-column>
                            <el-table-column :label="columns.height.title" v-if="columns.height.show" width="90">
                                <template slot-scope="scope">
                                    <crm-product-slot-input
                                        v-model="scope.row.height"
                                        :inputValue="scope.row.height"
                                        :type="'number'"
                                        :size="'mini'"
                                        :measurement_type="'sm'"
                                        :v_show="showInputCheck(scope.row)"
                                        @input="updateWeight(scope.row)"
                                    ></crm-product-slot-input>
                                    <!-- <el-input
                                        v-show="showInputCheck(scope.row)"
                                        :min="0"
                                        type="number"
                                        size="mini"
                                        @input="updateWeight(scope.row)"
                                        v-model="scope.row.height"
                                    ></el-input> -->
                                </template>
                            </el-table-column>
                            <el-table-column :label="columns.length.title" v-if="columns.length.show" width="90">
                                <template slot-scope="scope">
                                    <crm-product-slot-input
                                        v-model="scope.row.length"
                                        :inputValue="scope.row.length"
                                        :type="'number'"
                                        :size="'mini'"
                                        :measurement_type="'sm'"
                                        :v_show="showInputCheck(scope.row)"
                                        @input="updateWeight(scope.row)"
                                    ></crm-product-slot-input>
                                    <!-- <el-input
                                        v-show="showInputCheck(scope.row)"
                                        :min="0"
                                        type="number"
                                        size="mini"
                                        @input="updateWeight(scope.row)"
                                        v-model="scope.row.length"
                                    ></el-input> -->
                                </template>
                            </el-table-column>

                            <el-table-column :label="$t('message.comment')">
                                <template slot-scope="scope"> 
                                    <span v-show="scope.row.hasOwnProperty('comment')"> {{ scope.row.comment }} </span>
                                    <span  v-show="!scope.row.hasOwnProperty('comment')">{{scope.row.containerType ? scope.row.containerType.name : ''}}</span>
                                </template>
                            </el-table-column>

                            <el-table-column :label="$t('message.update')" width="85">
                                <template slot-scope="scope"> 
                                    <el-button v-if="scope.row.type == 'product' && scope.row.batches && scope.row.batches.length > 1"
                                        @click="showAndUpdateBatches(scope.row)" 
                                        type="primary" icon="el-icon-edit" circle>
                                    </el-button> 
                                    <el-button v-if="scope.row.type == 'package'"
                                        @click="showPackageProducts(scope.row)" 
                                        type="primary" icon="el-icon-view" circle>
                                    </el-button>
                                    <el-button v-if="scope.row.type == 'package' && scope.row.parcel"
                                        @click="updateParcelProducts(scope.row)" 
                                        type="primary" icon="el-icon-view" circle>
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>         
                </div>
            </div>
        </div>
    </div>

    
    <!-- Product partiyalar bo'yicha -->
    <el-dialog 
        :append-to-body="true"
        width="90%"
        :title="$t('message.product_inventorization')" 
        :visible.sync="batchDialog">
        <div v-if="selectedProduct.batches">
            <el-table :data="selectedProduct.batches" border stripe>
                <el-table-column type="index" width="50"> </el-table-column>
                <el-table-column :label="$t('message.name')">
                    <template> {{ selectedProduct.product ? selectedProduct.product.name : '' }} </template>
                </el-table-column>
                
                <el-table-column :label="$t('message.quantity_y')">
                    <template slot-scope="scope"> {{ scope.row.remainder }} </template>
                </el-table-column>

                <el-table-column :label="$t('message.barcode')">
                    <template slot-scope="scope"> {{ scope.row.barcode }} </template>
                </el-table-column>
                
                <el-table-column :label="$t('message.current_quantity')">
                    <template slot-scope="scope">
                        <el-input
                            :min="0"
                            type="number"
                            size="mini"
                            @input="checkValue(scope.row)"
                            v-model="scope.row.incoming_quantity"
                        ></el-input>
                    </template>
                </el-table-column>

                <el-table-column :label="columns.measuring_type.title" v-if="columns.measuring_type.show">
                    <template slot-scope="scope">
                        <select-measuring-type
                        :size="'mini'"
                        :placeholder="columns.measuring_type.title"
                        :id="scope.row.measuring_type_id"
                        v-model="scope.row.measuring_type_id"
                        @input="updateWeight(scope.row)"
                        ></select-measuring-type>
                    </template>
                </el-table-column>

                <el-table-column :label="columns.weight.title">
                    <template slot-scope="scope">
                        <!-- weight block (kg) -->
                        <crm-product-slot-input
                            v-model="scope.row.weight"
                            :inputValue="scope.row.weight"
                            :type="'number'"
                            :size="'mini'"
                            :disabled="scope.row.measuring_type_id === 2"
                            :measurement_type="'kg'"
                            @input="updatePoundAndKG(scope.row, 'weight_in_kg')"
                        ></crm-product-slot-input>

                        <!-- weight in pounds block (lbs) -->
                        <crm-product-slot-input
                            :v_show="!(scope.row.measuring_type_id === 2)"
                            v-model="scope.row.weight_in_pounds"
                            :inputValue="scope.row.weight_in_pounds"
                            :type="'number'"
                            :size="'mini'"
                            :class="'mt-1'"
                            :measurement_type="'lbs'"
                            @input="updatePoundAndKG(scope.row, 'weight_in_pounds')"
                        ></crm-product-slot-input>

                        <!-- <el-input 
                            :min="0" 
                            :disabled="scope.row.measuring_type_id === 2"
                            type="number" 
                            size="mini" 
                            @input="updatePoundAndKG(scope.row, 'weight_in_kg')"
                            v-model="scope.row.weight"
                        ></el-input> -->
                    </template>
                </el-table-column>

                <!-- <el-table-column :label="columns.weight_in_pounds.title">
                    <template slot-scope="scope">
                        <el-input 
                            v-show="!(scope.row.measuring_type_id === 2)"
                            :min="0" 
                            type="number" 
                            size="mini" 
                            @input="updatePoundAndKG(scope.row, 'weight_in_pounds')"
                            v-model="scope.row.weight_in_pounds"
                        ></el-input>
                    </template>
                </el-table-column> -->

                <el-table-column :label="columns.width.title" v-if="columns.width.show">
                    <template slot-scope="scope">
                        <crm-product-slot-input
                            v-model="scope.row.width"
                            :inputValue="scope.row.width"
                            :type="'number'"
                            :size="'mini'"
                            :measurement_type="'sm'"
                            :disabled="!(scope.row.measuring_type_id === 2)"
                            @input="updateWeight(scope.row)"
                        ></crm-product-slot-input>
                        <!-- <el-input
                            :disabled="!(scope.row.measuring_type_id === 2)"
                            :min="0"
                            type="number"
                            size="mini"
                            @input="updateWeight(scope.row)"
                            v-model="scope.row.width"
                        ></el-input> -->
                    </template>
                </el-table-column>
                <el-table-column :label="columns.height.title" v-if="columns.height.show">
                    <template slot-scope="scope">
                        <crm-product-slot-input
                            v-model="scope.row.height"
                            :inputValue="scope.row.height"
                            :type="'number'"
                            :size="'mini'"
                            :measurement_type="'sm'"
                            :disabled="!(scope.row.measuring_type_id === 2)"
                            @input="updateWeight(scope.row)"
                        ></crm-product-slot-input>
                        <!-- <el-input
                            :disabled="!(scope.row.measuring_type_id === 2)"
                            :min="0"
                            type="number"
                            size="mini"
                            @input="updateWeight(scope.row)"
                            v-model="scope.row.height"
                        ></el-input> -->
                    </template>
                </el-table-column>
                <el-table-column :label="columns.length.title" v-if="columns.length.show">
                    <template slot-scope="scope">
                        <crm-product-slot-input
                            v-model="scope.row.length"
                            :inputValue="scope.row.length"
                            :type="'number'"
                            :size="'mini'"
                            :measurement_type="'sm'"
                            :disabled="!(scope.row.measuring_type_id === 2)"
                            @input="updateWeight(scope.row)"
                        ></crm-product-slot-input>
                        <!-- <el-input
                        :disabled="!(scope.row.measuring_type_id === 2)"
                        :min="0"
                        type="number"
                        size="mini"
                        @input="updateWeight(scope.row)"
                        v-model="scope.row.length"
                        ></el-input> -->
                    </template>
                </el-table-column>
            </el-table>

            <div class="mt-4 cargo__right">
                <el-button @click="updateIncomingProducts()" type="primary" plain>{{ $t('message.save') }}</el-button>
            </div>
        </div>
    </el-dialog>

    <!-- Upakovka productlari -->
    <el-dialog class="dialog__modal" :title="$t('message.products')" :visible.sync="dialogProductsList" width="60%" @closed="emptyCortainerProducts()" :append-to-body="true" >
        <div v-loading="loadingProducts">
            <el-table :data="containerProducts">
                <el-table-column type="index" width="50"> </el-table-column>
                <el-table-column prop="deal_id" :label="$t('message.deal')" width="90"> </el-table-column>
                <el-table-column prop="name" :label="$t('message.name')">
                    <template slot-scope="scope">
                        {{ scope.row.product ? scope.row.product.name : '' }}
                    </template>
                </el-table-column>

                <el-table-column prop="comment" :label="$t('message.comment')">
                    <template slot-scope="scope">
                        {{ scope.row.comment ? scope.row.comment : '' }}
                    </template>
                </el-table-column>

                <el-table-column prop="barcode" :label="$t('message.barcode')"></el-table-column>
                <el-table-column prop="remainder" :label="$t('message.quantity')"></el-table-column>

                <el-table-column prop="weight" :label="$t('message.weight_in_kg')"></el-table-column>
                <el-table-column prop="weight_in_pounds" :label="$t('message.weight_in_pounds')"></el-table-column>
            </el-table>
        </div>
    </el-dialog>

    <!-- product posilki -->
    <el-dialog 
        class="dialog__modal" 
        :title="$t('message.products')" 
        :append-to-body="true"
        :visible.sync="dialogParcelProductsList" 
        width="60%" 
        @opened="$refs['parcelProducts'] ? $refs['parcelProducts'].afterOpen() : ''">
        <parcel-products-list ref="parcelProducts" :selectedItem="selectedProd"></parcel-products-list>
    </el-dialog>

</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import { formatMoney, formatNumber } from "@/filters/index";
import selectMeasuringType from "@/components/inventory/select-measuring-type";
import CrmProductSlotInput from '@/components/inputs/crm-product-slot-input';
import parcelProductsList from "@/views/waiting_product/components/parcel-products-list";

export default {
    mixins: [form, drawer],
    components: {
        selectMeasuringType,
        CrmProductSlotInput,
        parcelProductsList
    },

    props: {
        container:{
            default: null
        },
    },

    data: () => ({
        mode: true,
        checked: true,
        dealProductsList: [],
        loadingData: false,
        batchDialog: false,
        selectedProduct: {},
        barcode_search: '',
        loadingProducts: false,
        dialogProductsList: false,
        dialogParcelProductsList: false,
        selectedProd: {}
    }),
    computed: {
        ...mapGetters({
            model: "containers/model",
            columns: "dealProducts/columns",
            containerProducts: "packageForProductCirculations/products",
        }),
        total_weight: function () {
            let total = 0;
            this.dealProductsList.forEach((product) => {
                if(product.type === 'product'){
                    if(product.batches && product.batches.length > 1){
                        product.batches.forEach(batch => {
                            total += parseFloat(batch.weight);
                        });
                    }else{
                        total += parseFloat(product.weight);
                    }
                }
                if(product.type === 'package'){
                    total += parseFloat(product.total_weight);
                }

            });
            return parseFloat(total);
        },
        product_total_count: function () {
            let total = 0;
            this.dealProductsList.forEach((product) => {
                total += parseFloat(product.incoming_quantity);
            });
            return total;
        },

    },
     methods: {
        ...mapActions({
            show: "containers/show",
            storeProductsInFilial: "containers/storeContainerProductsInFilial",
            emptyCortainerProducts: "packageForProductCirculations/empty",
            showProducts: "packageForProductCirculations/showProducts",
        }),
        updateParcelProducts(parcel) {
            this.selectedProd = parcel;
            this.dialogParcelProductsList = true;
        },
        afterOpen(){
            this.fetchData();
        },  
        afterLeave(){
            this.dealProductsList = [];
        },  
        fetchData(){
            if(this.$refs['scanRef']){
                this.$refs['scanRef'].focus(); 
            } 
            this.loadingData = true;  
            this.show({id: this.container.id, type: 'inventory'}).then(res => {
                this.loadingData = false;
                let prods = JSON.parse(JSON.stringify(this.model.products.filter(el => el.remainder > 0)));
                let packs = JSON.parse(JSON.stringify(this.model.packages));
                this.dealProductsList = prods.concat(packs);
            }).catch(err => {
                this.loadingData = false;
            });
        },
        showInputCheck(row){
            if(row.type === 'product' && (row.measuring_type_id === 2) && (!row.batches || row.batches.length <= 1)) {
                return true;
            }
            return false;
        },
        updatePoundAndKG(row, type){
            if(type === 'weight_in_kg'){
                this.$set(row, 'weight_in_pounds',  _.round(parseFloat(row.weight) * this.$kg_to_pound, 6));
            }else{
                this.$set(row, 'weight',  _.round(parseFloat(row.weight_in_pounds) / this.$kg_to_pound, 6));
            }
        },
        updateWeight(row){
            if(row.measuring_type_id && row.measuring_type_id === 2){
                let size_weight = (parseFloat(row.width) * parseFloat(row.length) * parseFloat(row.height)) / 6000;
                this.$set(row, 'weight', _.round(parseFloat(size_weight), 6));
                this.$set(row, 'weight_in_pounds', '');
            }else{
                let current_row = this.dealProductsList.find(el => el.id === row.id);
                if(current_row){
                    let old_weight = JSON.parse(JSON.stringify(current_row.weight));
                    this.$set(row, 'weight',  _.round(parseFloat(old_weight), 6));
                    this.$set(row, 'weight_in_pounds',  _.round(parseFloat(old_weight) * this.$kg_to_pound, 6));
                }
            }
        },
        showPackageProducts(pack){
            this.dialogProductsList = true;
            this.loadingProducts = true;
            this.showProducts(pack.id).then(res => {
                    this.loadingProducts = false;
                }).catch(err => {
                    this.loadingProducts = false;
                });
        },
        async updateIncomingProducts(){
            await this.updateBatches();
            this.selectedProduct = {};
            this.batchDialog = false;
        },
        updateBatches(){
            if(this.selectedProduct && this.selectedProduct.batches){
                this.dealProductsList.forEach(el => {
                    if(el.id === this.selectedProduct.id){
                        this.$set(el, 'batches', this.selectedProduct.batches);
                        let total_quantity = 0;
                        this.selectedProduct.batches.forEach(batch => {
                            total_quantity +=  parseFloat(batch.incoming_quantity);
                        });
                        this.$set(el, 'incoming_quantity', total_quantity);
                    }
                });
            }
        },
        showAndUpdateBatches(row){
            this.batchDialog = true;
            this.selectedProduct = JSON.parse(JSON.stringify(row));
        },
        showWeight(row){
            if(row.batches && row.batches.length > 1){
                let weights = 0;
                row.batches.forEach(element => {
                    weights += parseFloat(element.weight)
                });
                return _.round(weights, 6) ;
            }else{
                return row.weight;
            }
        },
        updateFormProducts(inventor_all = false){
            this.form.products = [];
            this.dealProductsList.forEach(el => {
                if(el.type === 'product'){
                    if(el.batches && el.batches.length > 1){
                        el.batches.forEach(item => {
                            if(item.incoming_quantity > 0 || inventor_all){
                                let current_quantity = parseFloat(item.incoming_quantity)
                                if(inventor_all) current_quantity = parseFloat(item.remainder)
                                this.form.products.push({
                                    deal_id:            el.deal_id, 
                                    barcode:            item.barcode, 
                                    incoming_quantity:  current_quantity, 
                                    measuring_type_id:  item.measuring_type_id, 
                                    weight:             item.weight, 
                                    width:              item.width, 
                                    height:             item.height, 
                                    length:             item.length, 
                                    type:               el.type
                                });
                            }
                        });
                    }else{
                        if(el.incoming_quantity > 0 || inventor_all){
                            let current_quantity = parseFloat(el.incoming_quantity)
                            if(inventor_all) current_quantity = parseFloat(el.remainder)
                            this.form.products.push({
                                deal_id:            el.deal_id, 
                                barcode:            el.barcode, 
                                incoming_quantity:  current_quantity,
                                measuring_type_id:  el.measuring_type_id, 
                                weight:             el.weight, 
                                width:              el.width, 
                                height:             el.height, 
                                length:             el.length,  
                                type:               el.type
                            });
                        }
                    }
                }else{
                    if(el.incoming_quantity > 0 || inventor_all){
                        this.form.products.push({
                            deal_id:            el.deal_id, 
                            barcode:            el.barcode, 
                            incoming_quantity:  parseFloat(1),
                            weight:             el.total_weight, 
                            total_weight:       el.total_weight, 
                            type:               el.type
                        });
                    }
                }
            });
        },
        async setContainerPorductsInFilial(inventor_all = false){
            await this.updateFormProducts(inventor_all);

            if(this.form.products.length > 0){
                this.loadingButton = true;
                this.storeProductsInFilial(this.form)
                    .then(res => {
                        this.loadingButton = false;
                        this.close();
                        this.$alert(res); 
                        this.parent().listChanged();
                        this.$emit('refreshFilialContainers');
                    }).catch(err => {
                        this.loadingButton = false;
                    });
            }else{
                this.$notify({
                    title: this.$t('message.product'),
                    type: "error",
                    offset: 130,
                    message: this.$t('message.check_prod_quantity_inventor')
                });
            }
            
        },
        tableRowClassName({row}){
            if(row.incoming_quantity > 0){
                return 'mm_scanned_row';
            }
        },

        checkValue(row){
            if(row.type && row.type == 'package' && (row.incoming_quantity || row.incoming_quantity > 1)){
                this.$set(row, 'incoming_quantity', 1);
            }
            if(parseFloat(row.incoming_quantity) > parseFloat(row.remainder) || parseFloat(row.incoming_quantity) < 0){
                this.$set(row, 'incoming_quantity', row.remainder)
            }
            if(!row.incoming_quantity){
                this.$set(row, 'incoming_quantity', 0)
            }
        },

        stringContainString(arr) {
            return arr[1].split('').every(function(letter) {
                return arr[0].indexOf(letter) != -1;
            });
        },
        scanContainerProductBarcodeDebounce: _.debounce(function(val) {
            this.scanContainerProductBarcode(val);
        }, 300),
        scanContainerProductBarcode(val){
            let upper_barcode = val.toUpperCase();
            if(upper_barcode && (this.stringContainString([upper_barcode, 'P-D-C']) || this.stringContainString([upper_barcode, 'P-F']))){
                let prod_added = false;
                let prod_found = false;
                let count = 0;
                for (const product of this.dealProductsList) {
                    count ++;
                    if(product.type === 'product'){
                        if(product.batches && product.batches.length > 1){
                            for (const batch of product.batches) {
                                if(batch.barcode === upper_barcode){
                                    if(batch.incoming_quantity < parseFloat(batch.remainder)){
                                        batch.incoming_quantity ++;
                                        product.incoming_quantity ++;
                                        prod_added = true;
                                    }else{
                                        this.insufficiant_prod_message();
                                    }
                                    prod_found = true;
                                }
                                if(prod_found) break;
                            }
                        }else if (product.barcode === upper_barcode){
                            if(product.incoming_quantity < parseFloat(product.remainder)){
                                product.incoming_quantity ++;
                                prod_added = true;
                            }else{
                                this.insufficiant_prod_message();
                            }
                            prod_found = true;
                        }
                    }else if(product.type === 'package' && product.barcode === upper_barcode){
                        if(product.incoming_quantity < 1){
                            product.incoming_quantity ++;
                            prod_added = true;
                        }else{
                            this.insufficiant_prod_message();
                        }
                        prod_found = true;
                    }
                    
                    if(prod_found){
                        prod_found = false;
                        if(prod_added){
                            prod_added = false;
                            this.$notify({
                                title: this.$t('message.product'),
                                type: "success",
                                offset: 130,
                                message: this.$t('message.product_added_success')
                            });
                        }
                        break;
                    }
                    if(this.dealProductsList.length === count){
                        this.$notify({
                            title: this.$t('message.product'),
                            type: "error",
                            offset: 130,
                            message: this.$t('message.product_not_found')
                        });
                    }
                }
                this.barcode_search = '';
            }
        },
        insufficiant_prod_message(){
            this.$notify({
                title: this.$t('message.product'),
                type: "error",
                offset: 130,
                message: this.$t('message.insufficient_goods')
            });
        },

        getSummaries(param) {
            const { columns } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 1) {
                    sums[index] = this.$t('message.total');
                    return;
                }
                if (column.label === this.columns.weight.title) {
                    sums[index] = formatNumber(this.total_weight, 1) + ' ' + this.$t('message.kg');
                    return;
                }
               
                if (column.label === (this.columns.weight.title + ' %')) {
                    sums[index] = formatMoney(this.weight, 2);
                    return;
                }
            });

            return sums;
        },
        
    }
    
}
</script>

<style>
 .el-table .mm_scanned_row td{
    background: rgb(28, 224, 28) !important;
 }
</style>
